    .laptop{
    width: 100%;
    max-width: 1200px;
    position: relative;
  }
  
  .laptop img{
    width: 100%;
  }
  
  .laptop iframe{
    position: absolute;
    width: 67.7%;
    height: 82%;
    background: #000;
    top: 7.1%;
    left: 17.5%;
  }